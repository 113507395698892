import type { IPremiers } from '~/types/movies';
import type { RouteLocationRaw } from 'vue-router';
import { createDurationFormatter } from '~/helpers/datetime';

const formatDuration = createDurationFormatter('ru-RU', 'short', ['hours', 'minutes'], 1, true, false);

export const usePremiers = (movie: IPremiers) => {
  const route = computed((): RouteLocationRaw => ({ name: 'movie-slug', params: { slug: movie.slug } }));
  const duration = computed(() => formatDuration(movie.duration * 1000));
  return {
    data: movie,
    activeImg: movie.world_premier_poster || movie.image,
    inactiveImg: movie.poster_middle || movie.image,
    name: movie.name,
    film: {
      year: movie.film.year,
      genre: movie.film.genre,
      duration,
    },
    route,
  };
};
